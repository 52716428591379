<template>
  <section class="patient-history">
    <pacient-header />

    <main-modal />

    <ul class="lista-history">
      <li
        class="relative pointer"
        @click="edit(item, index)"
        v-for="(item, index) in docs"
        :key="item.id"
      >
        <i class="absolute top0 flex-center" v-html="svg.document"></i>
        <div class="flex flex-between h100">
          <div>
            <h4 v-if="item.type === 'scrisoare'">scrisoare medicală</h4>
            <h4 v-else>{{ item.denumire | shorten }} {{ item.dinte }}</h4>
            <span>{{ moment(item.createdAt).format("DD MMM YYYY") }}</span>
          </div>
          <a
            @click.stop
            v-if="item.pics"
            :href="'/history-imgs/' + pacient._id + '/' + item.pics[0].name"
            target="_blank"
          >
            <img class="opa4" src="../assets/note.svg" alt="" />
          </a>
        </div>
      </li>
    </ul>

    <footer class="w100">
      <ul class="flex">
        <li class="text-center mright20px"
            v-for="(item,key) in meniu"
            :key="key">
          <span class="block text-center green mbott5px" v-html="item.label"></span>
          <i class="pointer flex-center mauto green-btn-hover"
             @click="openModal(item, key)"
             v-html="svg[key]"></i>
        </li>
      </ul>
    </footer>

  </section>
</template>

<script>
  import moment from 'moment';
  import 'moment/locale/ro';
  moment.locale('ro');
  import { mapState } from 'vuex';
  export default {
    name: 'patient-history',
    data () {
      return {
        meniu: {
          document: {
            modal: 'addDocumentModal',
            label: 'adaugă <br> document'
          }
        },
        svg: {
          document: `
<svg width="32px" height="40px" viewBox="0 0 32 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(0.383333, 0.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M24,14.6666667 L7.33333333,14.6666667 C6.96533333,14.6666667 6.66666667,14.9646667 6.66666667,15.3333333 C6.66666667,15.702 6.96533333,16 7.33333333,16 L24,16 C24.368,16 24.6666667,15.702 24.6666667,15.3333333 C24.6666667,14.9646667 24.368,14.6666667 24,14.6666667 Z" id="Path"></path>
            <path d="M7.33333333,10.6666667 L14,10.6666667 C14.368,10.6666667 14.6666667,10.3686667 14.6666667,10 C14.6666667,9.63133333 14.368,9.33333333 14,9.33333333 L7.33333333,9.33333333 C6.96533333,9.33333333 6.66666667,9.63133333 6.66666667,10 C6.66666667,10.3686667 6.96533333,10.6666667 7.33333333,10.6666667 Z" id="Path"></path>
            <path d="M24,20 L7.33333333,20 C6.96533333,20 6.66666667,20.298 6.66666667,20.6666667 C6.66666667,21.0353333 6.96533333,21.3333333 7.33333333,21.3333333 L24,21.3333333 C24.368,21.3333333 24.6666667,21.0353333 24.6666667,20.6666667 C24.6666667,20.298 24.368,20 24,20 Z" id="Path"></path>
            <path d="M24,25.3333333 L7.33333333,25.3333333 C6.96533333,25.3333333 6.66666667,25.6313333 6.66666667,26 C6.66666667,26.3686667 6.96533333,26.6666667 7.33333333,26.6666667 L24,26.6666667 C24.368,26.6666667 24.6666667,26.3686667 24.6666667,26 C24.6666667,25.6313333 24.368,25.3333333 24,25.3333333 Z" id="Path"></path>
            <path d="M24,30.6666667 L7.33333333,30.6666667 C6.96533333,30.6666667 6.66666667,30.9646667 6.66666667,31.3333333 C6.66666667,31.702 6.96533333,32 7.33333333,32 L24,32 C24.368,32 24.6666667,31.702 24.6666667,31.3333333 C24.6666667,30.9646667 24.368,30.6666667 24,30.6666667 Z" id="Path"></path>
            <path d="M21.6093333,0 L1.0658141e-14,0 L1.0658141e-14,40 L31.3333333,40 L31.3333333,9.724 L21.6093333,0 Z M22,2.276 L29.0573333,9.33333333 L22,9.33333333 L22,2.276 Z M1.33333333,38.6666667 L1.33333333,1.33333333 L20.6666667,1.33333333 L20.6666667,10.6666667 L30,10.6666667 L30,38.6666667 L1.33333333,38.6666667 Z" id="Shape"></path>
        </g>
    </g>
</svg>`
        },
        moment
      };
    },
    filters: {
      shorten (val) {
        return val.length > 50 ? `${val.substr(0, 50)}... ` : val;
      }
    },
    computed: {
      ...mapState({
        pacient: 'pacient'
      }),
      docs () {
        return this.pacient.history
          ? this.pacient.history.filter(
              (obj) => obj.type === 'document' || obj.type === 'scrisoare'
            )
          : [];
      }
    },
    methods: {
      edit (item, index) {
        if (item.type === 'scrisoare') {
          const mutations = [
            {
              key: 'isEditScrisoare',
              value: true
            },
            {
              key: 'textScrisoare',
              value: item.text
            },
            {
              key: 'indexScrisoare',
              value: index
            },
            {
              key: 'printComponentName',
              value: 'scrisoareMedicala'
            }
          ];
          mutations.forEach((obj) => {
            this.$store.commit('SET_KEY_VALUE', obj);
          });
          const obj = {
            val: true,
            name: 'scrisoareMedicalaModal'
          };
          this.$store.commit('SET_MODAL', obj);
        } else {
          const obj = {
            name: this.meniu[item.type].modal,
            val: true,
            setValues: JSON.parse(JSON.stringify(item))
          };
          this.$store.commit('SET_MODAL', obj);
          this.$store.commit('SET_KEY_VALUE', {
            key: 'isUpdateModal',
            value: true
          });
        }
      },
      openModal (item, type) {
        const obj = {
          val: true,
          name: item.modal,
          setValues: {
            id: new Date().getTime(),
            type
          }
        };
        this.$store.commit('SET_KEY_VALUE', {
          key: 'isUpdateModal',
          value: false
        });
        this.$store.commit('SET_MODAL', obj);
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/mixin";
  @import "../assets/styles/var";
  .patient-history {
  }
</style>
